import * as React from "react";

const SvgDefaultChangeRequestScreen = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 122 122" 
   
    fill="none"
    {...props}
  >
    <g clipPath="url(#default-change-request-screen_svg__a)">
      <path
        fill="#066"
        d="M96.583 50.833c14.035 0 25.417-11.381 25.417-25.416S110.618 0 96.583 0 71.167 11.382 71.167 25.417s11.381 25.416 25.416 25.416m-10.166-30.5H91.5V15.25a5.085 5.085 0 0 1 5.083-5.083 5.085 5.085 0 0 1 5.084 5.083v5.083h5.083a5.085 5.085 0 0 1 5.083 5.084 5.085 5.085 0 0 1-5.083 5.083h-5.083v5.083a5.086 5.086 0 0 1-5.084 5.084 5.085 5.085 0 0 1-5.083-5.084V30.5h-5.083a5.085 5.085 0 0 1-5.084-5.083 5.085 5.085 0 0 1 5.084-5.084m-36.204 53.61L3.782 27.505C8.25 20.19 16.236 15.25 25.417 15.25h37.078A35.5 35.5 0 0 0 61 25.417c0 16.058 10.644 29.625 25.26 34.048L71.786 73.942A15.22 15.22 0 0 1 61 78.395a15.23 15.23 0 0 1-10.787-4.453m71.706-23.557L122 96.583C122 110.598 110.598 122 96.583 122H25.417C11.402 122 0 110.598 0 96.583V40.667c0-.798.163-1.55.234-2.328L43.025 81.13c4.957 4.956 11.463 7.432 17.975 7.432s13.018-2.476 17.975-7.432l20.262-20.262a35.46 35.46 0 0 0 22.687-10.482z"
      />
    </g>
    <defs>
      <clipPath id="default-change-request-screen_svg__a">
        <path fill="#fff" d="M0 0h122v122H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgDefaultChangeRequestScreen;
