// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.trash-container {
    padding: 0.5rem 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.5px solid var(--borderColor);
    cursor:pointer  !important;
    }
.trash-content{
    background-color: white;
    border-radius: 8px;
    /* box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3); */
}


.trash-title {
    display: flex;
    flex-direction: row;
    align-items: start;
    width: 65%;
    font-size: var(--twelve-font-size);
    font-weight: 600;
    color: var(--textColor);
    overflow: hidden;
  }
  .trash-title span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .trash-detail {
    font-size: var(--ten-font-size);
    font-weight: 400;
    color: var(--lightTextColor);
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-grow: 1;
  }
  
  .trash-detail span {
    margin: 0rem 0.8rem;
  }

  /*****************************************************************************/

  @media (max-width:768px) 
  {
    .trash-container{
        padding: 0.2rem 0.5rem;
    }

    .trash-detail span {
        margin: 0px 0.4rem;
    }
    
  }`, "",{"version":3,"sources":["webpack://./src/components/Trash/trash-style.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;IACnB,6CAA6C;IAC7C,0BAA0B;IAC1B;AACJ;IACI,uBAAuB;IACvB,kBAAkB;IAClB,gDAAgD;AACpD;;;AAGA;IACI,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,UAAU;IACV,kCAAkC;IAClC,gBAAgB;IAChB,uBAAuB;IACvB,gBAAgB;EAClB;EACA;IACE,mBAAmB;IACnB,gBAAgB;IAChB,uBAAuB;EACzB;;EAEA;IACE,+BAA+B;IAC/B,gBAAgB;IAChB,4BAA4B;IAC5B,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,mBAAmB;EACrB;;EAEA,8EAA8E;;EAE9E;;IAEE;QACI,sBAAsB;IAC1B;;IAEA;QACI,kBAAkB;IACtB;;EAEF","sourcesContent":[".trash-container {\n    padding: 0.5rem 1rem;\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    border-bottom: 0.5px solid var(--borderColor);\n    cursor:pointer  !important;\n    }\n.trash-content{\n    background-color: white;\n    border-radius: 8px;\n    /* box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3); */\n}\n\n\n.trash-title {\n    display: flex;\n    flex-direction: row;\n    align-items: start;\n    width: 65%;\n    font-size: var(--twelve-font-size);\n    font-weight: 600;\n    color: var(--textColor);\n    overflow: hidden;\n  }\n  .trash-title span {\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n  }\n\n  .trash-detail {\n    font-size: var(--ten-font-size);\n    font-weight: 400;\n    color: var(--lightTextColor);\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-end;\n    flex-grow: 1;\n  }\n  \n  .trash-detail span {\n    margin: 0rem 0.8rem;\n  }\n\n  /*****************************************************************************/\n\n  @media (max-width:768px) \n  {\n    .trash-container{\n        padding: 0.2rem 0.5rem;\n    }\n\n    .trash-detail span {\n        margin: 0px 0.4rem;\n    }\n    \n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
